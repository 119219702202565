import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { getGroups, sortSessionDetails } from 'queries/groups';

import { Database } from 'libs/supabaseTypes';

export type SessionData = Database['public']['Tables']['sessions']['Row'];
export type ClosingData = Database['public']['Tables']['closings']['Row'];
export type RetroData = Database['public']['Tables']['retros']['Row'];
type GroupData = Database['public']['Tables']['groups']['Row'];

interface MemberGroupDataContext {
    group: GroupData | null;
    scheduledSessions: Array<SessionData>;
    closings: Array<ClosingData>;
    getClosings: (() => Promise<void>) | null;
    retros: Array<RetroData>;
    getRetros: (() => Promise<void>) | null;
    sessionDetails: ReturnType<typeof sortSessionDetails>;
}

const memberGroupDataDefaultState: MemberGroupDataContext = {
    group: null,
    scheduledSessions: [],
    sessionDetails: [],
    closings: [],
    getClosings: null,
    retros: [],
    getRetros: null,
};

const MemberGroupDataContext = createContext(memberGroupDataDefaultState);

export function MemberGroupDataProvider({ children }: PropsWithChildren) {
    const { userId, userProfile } = useAuth();
    const { showErrorToast } = useToast();

    const [group, setGroup] = useState<GroupData | null>(null);
    const [scheduledSessions, setScheduledSessions] = useState<
        Array<SessionData>
    >([]);
    const [closings, setClosings] = useState<Array<ClosingData>>([]);
    const [retros, setRetros] = useState<Array<RetroData>>([]);
    const [sessionDetails, setSessionDetails] = useState<
        ReturnType<typeof sortSessionDetails>
    >([]);

    const getGroup = useCallback(async () => {
        const { data, error } = await getGroups;

        if (error == null) {
            setGroup(data?.[0] ?? null);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    const getScheduledSessions = useCallback(async () => {
        const { data, error } = await supabase
            .from('sessions')
            .select('*')
            .eq('gid', userProfile?.member_groups?.[0] ?? '')
            .order('start');

        if (error == null) {
            setScheduledSessions(data);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast, userProfile?.member_groups]);

    const getClosings = useCallback(async () => {
        // an RLS policy restricts access only to closings that have been submitted for
        // a group you're in, so this returns learnings submitted by all group members
        const { data, error } = await supabase
            .from('closings')
            .select('*')
            .order('session_date', { ascending: false });

        if (error == null) {
            setClosings(data);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    const getRetros = useCallback(async () => {
        const { data, error } = await supabase
            .from('retros')
            .select('*')
            .order('created_at', { ascending: false });

        if (error == null) {
            setRetros(data);
        } else {
            showErrorToast(error.message);
        }
    }, [showErrorToast]);

    useEffect(() => {
        if (userId !== '') {
            getGroup();
            getScheduledSessions();
            getClosings();
            getRetros();
        }
    }, [getClosings, getGroup, getRetros, getScheduledSessions, userId]);

    const getSessionDetails = useCallback(async () => {
        // Cannot reuse groupProgramHistoryQuery function here or refetch
        // breaks due to an undiagnosed caching issue
        const { data, error } = await supabase
            .from('group_program_history')
            .select(
                `*,
                    group_program_history_session_details!inner(*),
                    session_details(
                        *,
                        session_details_modules!left(*),
                        modules(*,
                            exercises_frameworks(*)
                        )
                    )
                `
            )
            .eq('id', group?.group_program_id ?? '');

        if (error == null) {
            setSessionDetails(sortSessionDetails(data));
        } else {
            showErrorToast(error.message);
        }
    }, [group?.group_program_id, showErrorToast]);

    useEffect(() => {
        if (group != null && group?.group_program_id != null) {
            getSessionDetails();
        }
    }, [getSessionDetails, group]);

    return (
        <MemberGroupDataContext.Provider
            value={{
                group,
                scheduledSessions,
                closings,
                getClosings,
                retros,
                getRetros,
                sessionDetails,
            }}
        >
            {children}
        </MemberGroupDataContext.Provider>
    );
}

export const useMemberGroupData = () => useContext(MemberGroupDataContext);
