import React, { useEffect, useMemo, useState } from 'react';

import { Fonts, colors, spacing } from 'styles/theme';
import { Bumper } from 'styles/shared';

import { useToast } from 'hooks/useToast';

import { Loader } from 'components/ui/Loader';
import { MarkdownWrapper } from 'components/ui/MarkdownWrapper';

import {
    sortSessionDetails,
    sortModules,
    JoinedGroupProgramHistory,
} from 'queries/groups';
import { supabase } from 'hooks/useAuth';

interface SessionDescriptionsProps {
    groupProgramId: string;
}

export const SessionDescriptions = ({
    groupProgramId,
}: SessionDescriptionsProps) => {
    const { showErrorToast } = useToast();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedGroupProgramHistory, setSelectedGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);

    useEffect(() => {
        const getSelectedGroupProgramHistory = async () => {
            // Cannot reuse groupProgramHistoryQuery function here or refetch
            // breaks due to an undiagnosed caching issue
            const { data, error } = await supabase
                .from('group_program_history')
                .select(
                    `*,
                        group_program_history_session_details!inner(*),
                        session_details(
                            *,
                            session_details_modules!left(*),
                            modules(*,
                                exercises_frameworks(*)
                            )
                        )
                    `
                )
                .eq('id', groupProgramId);

            console.log(data, groupProgramId);

            if (error == null) {
                setIsLoading(false);
                const joinedGroupProgramHistory: JoinedGroupProgramHistory =
                    data;
                setSelectedGroupProgramHistory(joinedGroupProgramHistory);
            } else {
                showErrorToast(error.message);
            }
        };

        getSelectedGroupProgramHistory();
    }, [groupProgramId, showErrorToast]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(selectedGroupProgramHistory),
        [selectedGroupProgramHistory]
    );

    return (
        <>
            <Fonts.Heading6>{`Session Objectives`}</Fonts.Heading6>
            <Bumper $minHeight={spacing.xxLarge} />
            {isLoading ? (
                <Loader />
            ) : (
                sortedSessionDetails.map(
                    // Type check is working in development, but compiler flags on build
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    (sessionDetailsDatum, index) => {
                        const sortedModules = sortModules(
                            sessionDetailsDatum?.session_details_modules ?? [],
                            sessionDetailsDatum?.modules ?? []
                        );
                        return (
                            <>
                                <Fonts.XSmall
                                    $isCapitalized
                                    $isOpenSansSemiBold
                                    $color={colors.mountainAsh}
                                >{`Session ${index + 1}:`}</Fonts.XSmall>
                                <Fonts.Heading7>
                                    {sessionDetailsDatum?.title}
                                </Fonts.Heading7>
                                <Bumper $minHeight={spacing.small} />
                                {(sessionDetailsDatum?.description?.length ??
                                    0) > 0 ? (
                                    <MarkdownWrapper
                                        markdownString={
                                            sessionDetailsDatum?.description ??
                                            ''
                                        }
                                    />
                                ) : (
                                    sortedModules.map((module, moduleIndex) => (
                                        <>
                                            {moduleIndex !== 0 ? (
                                                // TODO: remove temporary inline styling
                                                <div
                                                    style={{
                                                        marginTop: '-28px',
                                                    }}
                                                />
                                            ) : null}
                                            <MarkdownWrapper
                                                key={`${index}--${moduleIndex}`}
                                                markdownString={
                                                    module?.description ?? ''
                                                }
                                            />
                                        </>
                                    ))
                                )}
                                <Bumper $minHeight={spacing.medium} />
                            </>
                        );
                    }
                )
            )}
        </>
    );
};
