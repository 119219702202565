import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { supabase, useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { MERGED_PRECURSOR_GROUPS } from 'libs/constants';

import { Loader } from 'components/ui/Loader';

import { GrandLogoVert } from 'components/svg/GrandLogoVert';
import { GrandLogoHorzTan } from 'components/svg/GrandLogoHorzTan';
import { Like } from 'components/svg/Like';
import { Dislike } from 'components/svg/Dislike';

import { isMediumScreenSize, Fonts, colors, spacing } from 'styles/theme';
import { FullBleedScroller, Bumper } from 'styles/shared';

// Legacy components, assets, and styles
import ReportDropdown from 'legacy/components/ReportDropdown';
import {
    CumulativeDataContent,
    CumulativeDataSection,
    CumulativeDataWrapper,
    Heading,
    LargeNumberText,
    BodyLink,
    BulletListItem,
    MainWrapper,
    BulletList,
    ReportDetailsWrapper,
    TopBarText,
    TopBarWrapper,
    GroupReportListWrapper,
    GroupReportCard,
    RatingOuterWrapper,
    RatingImageWrapper,
    Divider,
} from 'legacy/styles/groupReports';
import { JoinedGroupProgramHistory, sortSessionDetails } from 'queries/groups';

export type SponsorData = Database['public']['Tables']['sponsors']['Row'];
export type ReportData = Database['public']['Tables']['reports']['Row'];
export type GroupData =
    | Database['public']['Tables']['groups']['Row']
    | undefined;

export const GroupReports = () => {
    const { userProfile } = useAuth();
    const { showSuccessToast, showErrorToast } = useToast();

    const [, setSearchParams] = useSearchParams();

    const [isLoadingSponsorData, setIsLoadingSponsorData] =
        useState<boolean>(true);
    const [sponsor, setSponsor] = useState<SponsorData | null>(null);
    const [sponsorGroupsWithReports, setSponsorGroupsWithReports] = useState<
        Array<GroupData> | []
    >([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
    const [selectedGroupProgramHistory, setSelectedGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);
    const [allReports, setAllReports] = useState<Array<ReportData>>([]);
    const [selectedGroupReports, setSelectedGroupReports] = useState<
        Array<ReportData>
    >([]);

    const getAllSponsorData = useCallback(async () => {
        const { data: sponsorData, error: sponsorError } = await supabase
            .from('sponsors')
            .select('*');

        if (sponsorError == null && sponsorData.length > 0) {
            setSponsor(sponsorData[0] ?? null);
        } else {
            showErrorToast(
                sponsorError?.message ??
                    'There was an error fetching sponsor data'
            );
            return;
        }

        const { data: groupData, error: groupError } = await supabase
            .from('groups')
            .select('*');
        if (groupError != null || groupData.length === 0) {
            showErrorToast(
                groupError?.message ?? 'There was an error fetching group data'
            );
            return;
        }

        const { data: reportData, error: reportError } = await supabase
            .from('reports')
            .select('*')
            .eq('is_published', true)
            .order('start_date', { ascending: false });
        if (reportError == null && reportData.length > 0) {
            setAllReports(reportData);
        } else {
            showErrorToast(
                reportError?.message ??
                    'There was an error fetching report data'
            );
            return;
        }

        const sponsorGroupsWithReportsData = reportData.map((reportDatum) =>
            groupData.find(
                (groupDatum) => groupDatum.gid === reportDatum.group_id
            )
        );
        setSponsorGroupsWithReports(sponsorGroupsWithReportsData);

        if (groupData.length === 1) {
            setSelectedGroup(groupData[0] ?? null);
        }

        setIsLoadingSponsorData(false);
    }, [showErrorToast]);

    useEffect(() => {
        if (userProfile?.sponsor_id != null) {
            getAllSponsorData();
        }
    }, [getAllSponsorData, userProfile?.sponsor_id]);

    const getSelectedGroupProgramHistory = useCallback(async () => {
        if (selectedGroup?.group_program_id == null) {
            return;
        }

        // Cannot reuse groupProgramHistoryQuery function here or refetch
        // breaks due to an undiagnosed caching issue
        const { data, error } = await supabase
            .from('group_program_history')
            .select(
                `*,
                    group_program_history_session_details!inner(*),
                    session_details(
                        *,
                        session_details_modules!left(*),
                        modules(*,
                            exercises_frameworks(*)
                        )
                    )
                `
            )
            .eq('id', selectedGroup?.group_program_id);

        if (error == null) {
            const joinedGroupProgramHistory: JoinedGroupProgramHistory = data;
            setSelectedGroupProgramHistory(joinedGroupProgramHistory);
        } else {
            showErrorToast(error.message);
        }
    }, [selectedGroup?.group_program_id, showErrorToast]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(selectedGroupProgramHistory),
        [selectedGroupProgramHistory]
    );

    useEffect(() => {
        if (selectedGroup?.group_program_id != null) {
            getSelectedGroupProgramHistory();
        }
    }, [getSelectedGroupProgramHistory, selectedGroup?.group_program_id]);

    useEffect(() => {
        setSelectedGroupReports(
            structuredClone(
                allReports.filter(
                    (report) => report.group_id === selectedGroup?.gid
                )
            )
        );
    }, [allReports, selectedGroup?.gid]);

    const dedupedOtherGroups = useMemo(
        () =>
            [
                ...new Set(
                    structuredClone(sponsorGroupsWithReports).filter(
                        (group: GroupData) => group?.gid !== selectedGroup?.gid
                    )
                ),
            ] as Array<GroupData>,
        [selectedGroup?.gid, sponsorGroupsWithReports]
    );

    const onSelectGroupLink = useCallback(
        (group: GroupData) => () => {
            setSelectedGroup(group);
        },
        []
    );

    const onSelectGroupViaReport = useCallback(
        (groupId: string) => () => {
            const groupToSelect = sponsorGroupsWithReports.find(
                (group) => group?.gid === groupId
            );
            if (groupToSelect != null) {
                setSelectedGroup(groupToSelect);
            }
        },
        [sponsorGroupsWithReports]
    );

    const mostRecentReport = selectedGroupReports[0] ?? null;

    const rateMostRecentReport = useCallback(
        (isPositive: boolean) => async () => {
            const { error } = await supabase
                .from('reports')
                .update({
                    sponsor_rating: isPositive ? 5 : 1,
                })
                .eq('id', mostRecentReport?.id ?? '');

            if (error == null) {
                getAllSponsorData();
                showSuccessToast('Thanks for your feedback!');
            } else {
                showErrorToast(
                    error?.message ??
                        'There was an error when rating this report'
                );
            }
        },
        [
            getAllSponsorData,
            mostRecentReport?.id,
            showErrorToast,
            showSuccessToast,
        ]
    );

    const mostRecentReportPerGroup: Array<ReportData> = useMemo(() => {
        const reportsToReturn: Array<ReportData> = [];
        allReports.forEach((report) => {
            let doesGroupReportExist = false;
            for (let i = 0; i < reportsToReturn.length; i++) {
                if (reportsToReturn[i]?.group_id === report.group_id) {
                    doesGroupReportExist = true;
                }
            }
            if (!doesGroupReportExist) {
                reportsToReturn.push(report);
            }
        });
        return reportsToReturn;
    }, [allReports]);

    const onOpenSessionObjectives = useCallback(() => {
        selectedGroup?.group_program_id
            ? setSearchParams({
                  modalContent: 'session-descriptions',
                  groupProgramId: selectedGroup.group_program_id,
              })
            : showErrorToast('There was an error fetching objectives');
    }, [setSearchParams, showErrorToast, selectedGroup]);

    return (
        <FullBleedScroller>
            {selectedGroup == null ? (
                <>
                    <Bumper />
                    <GrandLogoVert width={120} />
                    <Bumper />
                    <Bumper />
                    {isLoadingSponsorData ? (
                        <>
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Loader />
                        </>
                    ) : (
                        <>
                            {isMediumScreenSize ? (
                                <Fonts.Heading5 $textAlign="center">{`Welcome, ${sponsor?.sponsor_name}`}</Fonts.Heading5>
                            ) : (
                                <Fonts.Heading4 $textAlign="center">{`Welcome, ${sponsor?.sponsor_name}`}</Fonts.Heading4>
                            )}
                            <Bumper />
                            <Fonts.Medium $textAlign="center">{`Here are the reports for groups that you sponsor for The Grand:`}</Fonts.Medium>
                            <Bumper />
                            <Bumper />
                            <GroupReportListWrapper>
                                {mostRecentReportPerGroup.map((report) => (
                                    <GroupReportCard
                                        $linkEnabled={true}
                                        key={report.id}
                                        onClick={onSelectGroupViaReport(
                                            report.group_id
                                        )}
                                    >
                                        {isMediumScreenSize ? (
                                            <Fonts.Heading6
                                                $color={colors.mountainAsh}
                                            >
                                                {report.group_id}
                                            </Fonts.Heading6>
                                        ) : (
                                            <Fonts.Heading5
                                                $color={colors.mountainAsh}
                                            >
                                                {report.group_id}
                                            </Fonts.Heading5>
                                        )}
                                        <Bumper />
                                        <div>
                                            <Fonts.Small
                                                $color={colors.grey30}
                                            >{`Members active during report period:`}</Fonts.Small>
                                            <br />
                                            <Fonts.Medium>
                                                {report.active_members?.join(
                                                    ', '
                                                )}
                                            </Fonts.Medium>
                                        </div>
                                        <Bumper />
                                        <Fonts.Small
                                            $isUnderlined
                                        >{`View ${new Date(
                                            report.start_date
                                        ).toLocaleString('default', {
                                            timeZone: 'UTC',
                                            month: 'long',
                                            year: 'numeric',
                                        })} report >`}</Fonts.Small>
                                    </GroupReportCard>
                                ))}
                            </GroupReportListWrapper>
                        </>
                    )}
                </>
            ) : (
                <>
                    <MainWrapper>
                        <Heading>
                            <TopBarWrapper>
                                <GrandLogoHorzTan width={160} />
                                <TopBarText>
                                    <Fonts.XSmall $color={colors.light}>
                                        {`This data was aggregated on `}
                                        <Fonts.XSmall
                                            as="span"
                                            $color={colors.grandGold}
                                        >
                                            {new Date(
                                                mostRecentReport?.created_at ??
                                                    0
                                            ).toLocaleString('default', {
                                                timeZone: 'UTC',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </Fonts.XSmall>
                                        {mostRecentReport?.sessions_remaining ==
                                        null ? (
                                            `.`
                                        ) : (
                                            <>
                                                {` while the group had `}
                                                <Fonts.XSmall
                                                    as="span"
                                                    $color={colors.grandGold}
                                                >
                                                    {`${mostRecentReport?.sessions_remaining} sessions remaining`}
                                                </Fonts.XSmall>
                                                {`.`}
                                            </>
                                        )}
                                    </Fonts.XSmall>
                                </TopBarText>
                            </TopBarWrapper>

                            <Divider />

                            <CumulativeDataWrapper>
                                <Fonts.XSmall
                                    $isCapitalized
                                    $isOpenSansSemiBold
                                    $color={colors.light}
                                >
                                    {`Sponsor: `}
                                    <Fonts.XSmall
                                        $isCapitalized
                                        as="span"
                                        $color={colors.grandGold}
                                    >
                                        {sponsor?.sponsor_name}
                                    </Fonts.XSmall>
                                </Fonts.XSmall>
                                <Bumper />
                                <Fonts.XSmall
                                    $isCapitalized
                                    $isOpenSansSemiBold
                                    $color={colors.light}
                                >
                                    {`Cumulative stats for group: `}
                                </Fonts.XSmall>
                                <Bumper />
                                <Fonts.Heading3 $color={colors.light}>
                                    {mostRecentReport?.group_id}
                                </Fonts.Heading3>
                                {MERGED_PRECURSOR_GROUPS.includes(
                                    mostRecentReport?.group_id ?? ''
                                ) ? (
                                    <>
                                        <Bumper />
                                        <Fonts.Small
                                            $color={colors.white}
                                        >{`To maximize founders’ goals to build relationships and connection with each other, The Grand merged this group with another Precursor group that had a larger group size.`}</Fonts.Small>
                                    </>
                                ) : null}
                                <Bumper />
                                <Bumper />

                                <CumulativeDataContent>
                                    <CumulativeDataSection>
                                        <Fonts.Medium
                                            $isOpenSansSemiBold
                                            $color={colors.grandGold}
                                        >{`Members active during report period:`}</Fonts.Medium>
                                        <BulletList>
                                            {mostRecentReport?.active_members?.map(
                                                (activeMember) => (
                                                    <li key={activeMember}>
                                                        <BulletListItem
                                                            as="li"
                                                            $color={
                                                                colors.light
                                                            }
                                                        >
                                                            {activeMember}
                                                        </BulletListItem>
                                                    </li>
                                                )
                                            )}
                                        </BulletList>
                                        {dedupedOtherGroups.length > 0 ? (
                                            <>
                                                <Bumper />
                                                <Fonts.Medium
                                                    $isOpenSansSemiBold
                                                    $color={colors.grandGold}
                                                >{`Links to other group reports:`}</Fonts.Medium>
                                                <Bumper />
                                                {dedupedOtherGroups.map(
                                                    (group: GroupData) => (
                                                        <BodyLink
                                                            $isOpenSansSemiBold
                                                            key={group?.gid}
                                                            onClick={onSelectGroupLink(
                                                                group
                                                            )}
                                                            $color={
                                                                colors.light
                                                            }
                                                        >
                                                            {group?.gid}
                                                        </BodyLink>
                                                    )
                                                )}
                                                <Bumper />
                                            </>
                                        ) : null}
                                    </CumulativeDataSection>

                                    <CumulativeDataSection>
                                        <LargeNumberText $color={colors.light}>
                                            {mostRecentReport?.cumulative_session_score_avg?.toFixed(
                                                1
                                            )}
                                        </LargeNumberText>
                                        <Fonts.Medium
                                            $isOpenSansSemiBold
                                            $color={colors.grandGold}
                                        >{`Session Score Average`}</Fonts.Medium>
                                        <Bumper />
                                        <LargeNumberText $color={colors.light}>
                                            {mostRecentReport?.cumulative_csat_avg?.toFixed(
                                                1
                                            ) ?? 'Coming soon!'}
                                        </LargeNumberText>
                                        <Fonts.Medium
                                            $isOpenSansSemiBold
                                            $color={colors.grandGold}
                                        >{`CSAT Average`}</Fonts.Medium>
                                        <Fonts.XSmall
                                            $color={colors.grey20}
                                        >{`Customer satisfaction is averaged from survey responses at sessions ${
                                            sortedSessionDetails.length === 6
                                                ? '3 and 6'
                                                : '4, 8, and 12.'
                                        }`}</Fonts.XSmall>
                                    </CumulativeDataSection>
                                </CumulativeDataContent>
                            </CumulativeDataWrapper>
                        </Heading>
                        <ReportDetailsWrapper>
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            {isMediumScreenSize ? null : (
                                <>
                                    <Bumper />
                                </>
                            )}
                            {isMediumScreenSize ? (
                                <Fonts.Heading6>{`Monthly Stats`}</Fonts.Heading6>
                            ) : (
                                <Fonts.Heading5>{`Monthly Stats`}</Fonts.Heading5>
                            )}
                            <Bumper />
                            <BodyLink
                                $isUnderlined
                                onClick={onOpenSessionObjectives}
                            >{`Check out the Session Objectives here >`}</BodyLink>
                            <Bumper />
                            <Bumper />

                            {selectedGroupReports.map((report) => (
                                <ReportDropdown
                                    key={report.id}
                                    report={report}
                                />
                            ))}

                            <Bumper />
                            {mostRecentReport?.sponsor_rating != null ? null : (
                                <RatingOuterWrapper>
                                    {isMediumScreenSize ? (
                                        <Fonts.Heading7
                                            $color={colors.darkJungle}
                                        >{`Was this report helpful?`}</Fonts.Heading7>
                                    ) : (
                                        <Fonts.Heading6
                                            $color={colors.darkJungle}
                                        >{`Was this report helpful?`}</Fonts.Heading6>
                                    )}
                                    <RatingImageWrapper
                                        onClick={rateMostRecentReport(true)}
                                    >
                                        <Like width={32} color={colors.white} />
                                    </RatingImageWrapper>
                                    <RatingImageWrapper
                                        onClick={rateMostRecentReport(false)}
                                    >
                                        <Dislike
                                            width={32}
                                            color={colors.white}
                                        />
                                    </RatingImageWrapper>
                                </RatingOuterWrapper>
                            )}
                            <Bumper />
                            <Bumper />
                            <Bumper />
                        </ReportDetailsWrapper>
                    </MainWrapper>
                </>
            )}
            <Bumper $minHeight={spacing.xxxLarge} />
        </FullBleedScroller>
    );
};
