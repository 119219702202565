import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { TurnstileInstance } from '@marsidev/react-turnstile';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { generateRandomString } from 'libs/utils';

import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';

import { CONSUMER_SPONSOR_IDS } from 'libs/constants';

import { TextInput } from 'components/ui/TextInput';
import { Button } from 'components/ui/Button';
import { TurnstileWrapper } from 'components/ui/TurnstileWrapper';

import { GrandLogoVert } from 'components/svg/GrandLogoVert';
import { ChevronSmall } from 'components/svg/ChevronSmall';

import SignUpOneOnOneImage from 'assets/sign-up-one-on-one.png';
import SignUpDashboardImage from 'assets/sign-up-dashboard.png';

import { colors, Fonts, isMobileScreenSize, spacing } from 'styles/theme';
import { Bumper } from 'styles/shared';
import {
    MainWrapper,
    ScrollingContentSectionHeader,
    ScrollingContentImage,
    ScrollingContentSection,
    ScrollingContentWrapper,
    ScrollingContentMainHeader,
    ScrollCTA,
    SignUpFormWrapper,
    ScrollingContentSectionBodyText,
    SignUpForm,
    ChevronWrapper,
    ScrollCTAWrapper,
} from 'styles/signUp';

export const SignUp = () => {
    const navigate = useNavigate();
    const { tempGroupSponsorId, sponsorName } = useParams();

    const { isSignedIn, signUp } = useAuth();
    const { showErrorToast } = useToast();

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSigningUp, setIsSigningUp] = useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const temporaryPassword = useMemo(generateRandomString, []);

    useEffect(() => {
        if (
            tempGroupSponsorId?.match(
                /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/i
            ) == null
        ) {
            showErrorToast(
                `Your application URL is incorrectly formatted — please check your sponsor's link again`
            );
        }
    }, [showErrorToast, tempGroupSponsorId]);

    const onFirstNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setFirstName(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onLastNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLastName(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSigningUp) {
                return;
            }
            setIsSigningUp(true);

            if (captchaToken != null && !isSignedIn) {
                const error = await signUp?.({
                    email,
                    password: temporaryPassword,
                    captchaToken,
                    fullName: `${firstName} ${lastName}`,
                    tempGroupSponsorId: tempGroupSponsorId ?? '',
                });

                if (error == null) {
                    navigate(
                        `/confirm?email=${encodeURIComponent(email)}&type=email`
                    );
                } else {
                    turnstileRef.current?.reset();
                    setIsError(true);
                    showErrorToast(error.message);
                }
            }

            setIsSigningUp(false);
        },
        [
            captchaToken,
            email,
            firstName,
            isSignedIn,
            isSigningUp,
            lastName,
            navigate,
            showErrorToast,
            signUp,
            tempGroupSponsorId,
            temporaryPassword,
        ]
    );

    const renderScrollingContent = useCallback(
        () => (
            <ScrollingContentWrapper>
                <ScrollingContentSection>
                    <ScrollingContentSectionHeader
                        $color={colors.mountainAsh}
                        $isOpenSansSemiBold
                    >{`Welcome To`}</ScrollingContentSectionHeader>
                    <ScrollingContentMainHeader>{`The Grand World`}</ScrollingContentMainHeader>
                    {sponsorName == null ? null : (
                        <ScrollingContentSectionBodyText>
                            {CONSUMER_SPONSOR_IDS.includes(
                                tempGroupSponsorId ?? ''
                            )
                                ? `Apply to join The Grand and start your coaching journey`
                                : `Apply to join ${sponsorName?.replace(
                                      '-',
                                      ' '
                                  )} on your Grand Journey`}
                        </ScrollingContentSectionBodyText>
                    )}
                    <Bumper />
                    {isMobileScreenSize ? null : (
                        <>
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <Bumper />
                            <ScrollCTA>
                                <Fonts.Medium>{`Scroll down to learn more`}</Fonts.Medium>
                                <ChevronWrapper>
                                    <ChevronSmall />
                                </ChevronWrapper>
                            </ScrollCTA>
                        </>
                    )}
                </ScrollingContentSection>
                <ScrollingContentSection>
                    <ScrollingContentImage src={SignUpOneOnOneImage} />
                    <Bumper />
                    <Bumper />
                    <ScrollingContentSectionHeader
                        $color={colors.mountainAsh}
                        $isOpenSansSemiBold
                    >{`Guiding Journeys`}</ScrollingContentSectionHeader>
                    <Fonts.Heading7>{`At The Grand we believe coaching is getting someone from where they are to where they want to go.`}</Fonts.Heading7>
                </ScrollingContentSection>
                <ScrollingContentSection>
                    <ScrollingContentImage src={SignUpDashboardImage} />
                    <Bumper />
                    <Bumper />
                    <ScrollingContentSectionHeader
                        $color={colors.mountainAsh}
                        $isOpenSansSemiBold
                    >{`Assess and Achieve Goals`}</ScrollingContentSectionHeader>
                    <Fonts.Heading7>{`Fill out the intake form and take The Grand Diagnostic to get matched with a coaching group that's right for you.`}</Fonts.Heading7>
                </ScrollingContentSection>
            </ScrollingContentWrapper>
        ),
        [sponsorName, tempGroupSponsorId]
    );

    return (
        <>
            <MainWrapper>
                <SignUpFormWrapper>
                    <Bumper />
                    <GrandLogoVert width={140} />
                    <Bumper $minHeight={spacing.xxxLarge} />
                    <Fonts.Heading6 $color={colors.mountainAsh}>
                        {`Sign up`}
                    </Fonts.Heading6>
                    <Bumper />
                    <SignUpForm onSubmit={onSubmit}>
                        <TextInput
                            type="text"
                            id="firstName"
                            name="firstName"
                            onInput={onFirstNameInput}
                            $isError={isError}
                            label="First name"
                            required
                        />
                        <Bumper />
                        <TextInput
                            type="text"
                            id="lastName"
                            name="lastName"
                            onInput={onLastNameInput}
                            $isError={isError}
                            label="Last name"
                            required
                        />
                        <Bumper />
                        <TextInput
                            type="email"
                            id="email"
                            name="email"
                            onInput={onEmailInput}
                            $isError={isError}
                            label="Email address"
                            required
                        />
                        <Bumper />
                        <TurnstileWrapper
                            turnstileRef={turnstileRef}
                            onSuccess={setCaptchaToken}
                            $color={colors.light}
                        />
                        <Bumper />
                        <Button
                            type="submit"
                            value="Submit"
                            $isLoading={isSigningUp}
                            $isStretch
                        >
                            {`Next`}
                        </Button>
                        <Bumper />
                        <Link to="/sign-in">
                            <Fonts.Small
                                $isUnderlined
                            >{`Already have an account?`}</Fonts.Small>
                        </Link>
                        <Bumper $minHeight={spacing.xxxLarge} />
                    </SignUpForm>
                </SignUpFormWrapper>
                {renderScrollingContent()}
            </MainWrapper>

            {isMobileScreenSize ? (
                <ScrollCTAWrapper>
                    <ScrollCTA>
                        <Fonts.Small>{`Scroll down to learn more`}</Fonts.Small>
                        <ChevronWrapper>
                            <ChevronSmall />
                        </ChevronWrapper>
                    </ScrollCTA>
                </ScrollCTAWrapper>
            ) : null}
        </>
    );
};
